import { Controller } from "stimulus"

export default class extends Controller{
  static targets = ['practiceName']

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    this.practiceNameTarget.innerHTML = data.practice_name;
  }

  showInlineEditField(e) {
    e.preventDefault()
    this.hideAllCurrentPopUps()
    document.getElementById(e.target.name).className = 'popup true'
  }

  closePopup(e) {
    e.preventDefault()
    this.hideAllCurrentPopUps()
  }

  hideAllCurrentPopUps() {
    let popups = document.getElementsByClassName('popup')
    for (var i = 0; i < popups.length; i++){
      popups[i].className = 'popup'
    }
  }
}