import { Controller } from "stimulus"

export default class extends Controller{
  static targets = ['state']

  initialize() {
    this.state = false
  }

  toggle(e) {
    e.preventDefault()
    this.state = !this.state
    this.stateTarget.className = `expandable ${this.state}`
  }
}