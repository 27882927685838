import consumer from "./consumer";

consumer.subscriptions.create("PatientChannel", {
  received(data) {
    let modal_open = $('#patient-modal').hasClass('show');
    let patient_id = $('#patient-modal .modal-content').data('id');
    if ( !(modal_open && (patient_id == data.patient_id)) ) { return; }

    switch (data.action) {
      case 'update_subscription':
        $.get(`/office/members/${data.patient_id}`);
        break;

      case 'update_charge':
        $.get(`/office/members/${data.patient_id}/transactions`);
        break;
    }
  }
});